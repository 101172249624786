import React from "react"

import { AppLayout } from "../app.component"
import NotFoundTemplate from "../templates/not-found.template"

const Page404 = () => (
  <AppLayout>
    <NotFoundTemplate />
  </AppLayout>
)

export default Page404
